import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { DriveEta} from '@mui/icons-material';
import { Link } from 'react-router-dom';


export const mainListItems = (
  <React.Fragment>
    <ListItemButton >
      <ListItemIcon style={{ color: '#fff' }}>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={Link} to="/customer">
      <ListItemIcon style={{ color: '#fff' }}>
        <DriveEta />
      </ListItemIcon>
      <ListItemText primary="Request a delivery" />
    </ListItemButton>
  </React.Fragment>
);