import axios from './index'

export function getUserProfile() {
    return axios.get('/profile/basic')
}

export function updateUserProfile({
    firstName = '',
    lastName = '',
    profilePictureUrl = '',
} = {}) {
    return axios.put('/profile/basic', {
        firstName: firstName,
        lastName: lastName,
        profilePictureUrl: profilePictureUrl,
    });
}

export function updateUserEmail({
    emailAddress = '',
} = {}) {
    return axios.put('/profile/basic/emailAddress', {
        emailAddress: emailAddress,
    });
}

export function updateUserPassword({
    oldPassword = '',
    newPassword = '',
    confirmPassword = '',
} = {}) {
    return axios.put('/change-password', {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
    });
}

export function resetPasswordLink({
    emailAddress = '',
} = {}) {
    return axios.post('/auth/reset-password/email/initial', {
        emailAddress: emailAddress,
    });
}

export function resetPassword({
    emailAddress = '',
    verificationCode = '',
    newPassword = '',
    newPasswordConfirm = '',
} = {}) {
    return axios.post('/auth/reset-password/email/final', {
        emailAddress: emailAddress,
        verificationCode: verificationCode,
        newPassword: newPassword,
        newPasswordConfirm: newPasswordConfirm,
    });
}