import { notification } from 'antd';
import axios from './index'


export function driverResponse(driverId, {
    accepted = '',
} = {}) {
    if (accepted === '') {
        return Promise.reject(
            notification.error({
                message: 'Missing required param(s): driverOrIndividualUserEmail',
            }),
        )
    }
    return axios.put(`/driver/${driverId}/respond`, {
        accepted: accepted,
    });
}

export function driverAvailability({
    available = '',
} = {}) {
    return axios.put(`/driver/availability`, {
        available: available,
    });
}
//
export function driverTripStatus(driverId, {
    EVehicleTripStatus = '',
} = {}) {
    if (EVehicleTripStatus === '') {
        return Promise.reject(
            notification.error({
                message: 'Missing required param(s): vehicleTripStatus',
            }),
        )
    }
    return axios.put(`/driver/${driverId}/trip-status`, {
        vehicleTripStatus: EVehicleTripStatus,
    });
}

export function driverAcceptTripRequest(driverId, {
    accepted = '',
} = {}) {
    if (accepted === '') {
        return Promise.reject(
            notification.error({
                message: 'Missing required param(s): tripRequestId',
            }),
        )
    }
    return axios.put(`/driver/${driverId}/respond`, {
        accepted: accepted,
    });
}

