import React, { useState } from 'react'
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { notification } from 'antd';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { driverAvailability } from '../../service/driver.service';


const defaultTheme = createTheme();

const Availability = () => {
    const navigate = useNavigate();
    const [available, setAvailable] = useState('');

    const setDriverAvailability = (event) => {
        event.preventDefault();
        driverAvailability({ available })
            .then((res) => {
                if (res.data.status === 'success') {
                    notification.success({
                        message: 'Driver Availability updated successfully!',
                    })
                    navigate('/driver-dashboard');
                } else {
                    notification.error({
                        message: 'Driver Availability update failed! Please try again',
                    })
                }

            })
            .catch((error) => {
                notification.error({
                    message: 'Driver Availability update failed! Please try again',
                })
            })
    }

    const handleClose = () => {
        navigate('/driver-dashboard');
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#fff',
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                        padding: 2,
                        borderRadius: 1,
                        width: '30rem',
                        height: '18rem',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <Typography component="p" variant="p" sx={{ color: 'green', marginTop: '1rem', marginBottom: '2rem' }}>
                        Change Your Availability
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={setDriverAvailability}
                    >
                        <Grid item spacing={1.5}>
                            <FormControl sx={{ minWidth: 400 }} size="midium">
                                <InputLabel id="demo-select-small-label">
                                    Availability
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    required
                                    value={available}
                                    label="Availability"
                                    sx={{ textAlign: "left" }}
                                    onChange={(event) => setAvailable(event.target.value)}
                                    className="custom-select"
                                >
                                    <MenuItem value={'true'}>Available</MenuItem>
                                    <MenuItem value={'false'}>UnAvailable</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <Button
                                type="cancel"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    width: "30%",
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        backgroundColor: 'theme.palette.primary.main',
                                    },
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"  
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    width: "30%",
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        backgroundColor: 'theme.palette.primary.main',
                                    },
                                }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Availability;