import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { vehicleOnboarding } from "../service/onboarding.service";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import Copyright from "../copyright/Copyright";


const defaultTheme = createTheme();

const VehicleOnboardingService = () => {
  const [brand, setBrand] = useState('')
  const [color, setColor] = useState('')
  const [capacity, setCapacity] = useState('')
  const [registrationNumber, setRegistrationNumber] = useState('')
  const [EVehicleType, setEVehicleType] = useState('');
  const [EVehicleStatus, setEVehicleStatus] = useState('')
  const [EGoodsType, setEGoodsType] = useState('')
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');

  const vehicleService = (event) => {
    event.preventDefault();

    vehicleOnboarding({
      brand,
      color,
      capacity,
      registrationNumber,
      EVehicleType,
      EVehicleStatus,
      EGoodsType,
    })
      .then((res) => {
        notification.success({
          message: 'Vehicle Onboarded Successfully',
        });
        if (userType === 'CORPORATE') {
          navigate("/corporate-dashboard", { replace: true });
        } else {
          navigate("/individual-dashboard", { replace: true });
        }
      })
      .catch((error) => {
        notification.error({
          message: 'Vehicle Onboarding Failed',
        });
      });

  }

  const onCancel = () => {
    if (userType === 'CORPORATE') {
      navigate("/corporate-dashboard", { replace: true });
    } else {
      navigate("/individual-dashboard", { replace: true });
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: '#fff',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
            padding: 2,
            borderRadius: 1,
            width: '35rem',
            height: '35rem',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Vehicles Onboarding Service
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={vehicleService}
            sx={{ mt: 5 }}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12} sx={{
                width: 500,
                maxWidth: '100%',
              }}>

                <TextField
                  autoComplete="brand"
                  name="brand"
                  required
                  sx={{ width: 500, maxWidth: '100%'}}
                  id="name"
                  label="Brand Name"
                  autoFocus
                  size="small"
                  onChange={(event) => setBrand(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ width: 500, maxWidth: '100%'}}
                  id="color"
                  label="Color"
                  name="color"
                  autoComplete="color"
                  size="small"
                  onChange={(event) => setColor(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ width: 500, maxWidth: '100%'}}
                  id="capacity"
                  label="Capacity"
                  name="capacity"
                  autoComplete="email"
                  size="small"
                  onChange={(event) => setCapacity(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  sx={{ width: 500, maxWidth: '100%'}}
                  name="registration-number"
                  label="Registration Number"
                  type="text"
                  id="registration-number"
                  autoComplete="new-password"
                  size="small"
                  onChange={(event) => setRegistrationNumber(event.target.value)}
                />
              </Grid>
              <Grid xs={12}>
                <FormControl sx={{ width: 500, maxWidth: '100%', marginTop: '1rem', marginLeft: '1.2rem' }} size="small">
                  <InputLabel id="demo-select-small-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    required
                    value={EVehicleStatus}
                    label="Status"
                    name="status"
                    autoComplete="status"
                    sx={{ textAlign: "left" }}
                    onChange={(event) => setEVehicleStatus(event.target.value)}
                  >
                    <MenuItem value={'ACTIVE'}>Active</MenuItem>
                    <MenuItem value={'INACTIVE'}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item spacing={1.5} xs={12} sm={6}>
                <FormControl sx={{ width: 230, maxWidth: '100%'}} size="small">
                  <InputLabel id="demo-select-small-label">
                    Vehicle Type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    required
                    value={EVehicleType}
                    label="Vehicle Type"
                    sx={{ textAlign: "left" }}
                    onChange={(event) => setEVehicleType(event.target.value)}
                  >
                    <MenuItem value={'TRUCK'}>Truck</MenuItem>
                    <MenuItem value={'CAR'}>Car</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item spacing={1.5} xs={12} sm={6}>
                <FormControl sx={{ width: 230, maxWidth: '100%'}} size="small">
                  <InputLabel id="demo-select-small-label">
                    Type of Goods
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    required
                    value={EGoodsType}
                    label="Goods Type"
                    sx={{ textAlign: "left" }}
                    onChange={(event) => setEGoodsType(event.target.value)}
                  >
                    <MenuItem value={'GENERAL_PURPOSE'}>General Purpose</MenuItem>
                    <MenuItem value={'CONSTRUCTION'}>Construction</MenuItem>
                    <MenuItem value={'AGRICULTURE'}>Agriculture </MenuItem>
                    <MenuItem value={'FURNITURE'}>Furniture</MenuItem>
                    <MenuItem value={'ELECTRONIC'}>Electronic</MenuItem>
                    <MenuItem value={'OTHER'}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <Button
                type="cancel"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "30%" }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "30%" }}
              >
                Submit
              </Button>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};

export default VehicleOnboardingService;
