import React, { useState } from 'react'
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { resendVerification } from "../../service/maxon.service";
import { useNavigate } from "react-router-dom";
import { notification } from 'antd';


const defaultTheme = createTheme();

const ResendVerification = () => {
    const [emailAddress, setEmailAddress] = useState('')
    const navigate = useNavigate();

    const emailVerification = (event) => {
        event.preventDefault()
        resendVerification({ emailAddress })
            .then((res) => {
                if (res && res.status === 201) {
                    const token = res.data.token;
                    localStorage.setItem('token', token);
                    navigate('/verify');
                    notification.success({
                        message: 'Email Verification Success! You can now login to your account',
                    })
                } else {
                    notification.error({
                        message: 'Email Verification failed! Please make sure you have entered the correct verification code',
                    })
                }

            })
            .catch((error) => {
                notification.error({
                    message: 'Email Verification failed! Please make sure you have entered the correct verification code',
                })
            })

    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#fff',
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                        padding: 2,
                        borderRadius: 1,
                        width: '30rem',
                        height: '18rem',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <Typography component="p" variant="p" sx={{ color: 'green', marginTop: '1rem' }}>
                        Please enter your email address to receive a verification code
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={emailVerification}
                        sx={{ mt: 5 }}
                    >
                        <Grid container spacing={2.5}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={(event) => setEmailAddress(event.target.value)}
                                    style={{ width: '400px' }}
                                    sx={{
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            borderColor: '#6CB4EE',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#6CB4EE',
                                        },
                                    }}
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    backgroundColor: 'theme.palette.primary.main',
                                },
                            }}
                        >
                            Verify Email
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default ResendVerification