import { useState, useEffect } from "react";
import { notification, Table, Button, Select, Form, Modal, } from "antd";
import { getCustomerRideHistory } from "../service/dashboard.service";
import jsPDF from 'jspdf';
import MaxonLogo from '../assets/Maxon.png'

const { Option } = Select;

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};


const UserRidesHistory = () => {
    const [users, setUsers] = useState([]);
    const [isViewPostModalVisible, setIsViewPostModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [form] = Form.useForm();
    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "id",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedUser(post);
                        setIsViewPostModalVisible(true);
                    }}
                >
                    {index + 1}
                </span>
            ),
        },
        // { key: "customerName", title: "Name", render: (text, record) => `${record.customer.firstName} ${record.customer.lastName}` },
        // { key: "emailAddress", title: "Email", render: (text, record) => record.customer.emailAddress },
        { 
            key: "originLocationName", 
            title: "Origin", 
            dataIndex: "originLocationName",
            render: (text) => {
                const parts = text.split(',');
                return parts.slice(0, 5).join(',');
            }
        },
        {
            key: "destinationLocationName",
            title: "Destination",
            dataIndex: "destinationLocationName",
            render: (text) => {
                const parts = text.split(',');
                return parts.slice(0, 2).join(',');
            }
        },
        {
            key: "typeOfGoodsTransported", title: "Type Of Goods", dataIndex: "typeOfGoodsTransported",
            filters: [
                { text: 'Furniture', value: 'Furniture' },
                { text: 'AGRICULTURE', value: 'AGRICULTURE' },
                { text: 'Electronics', value: 'Electronics' },
                { text: 'CONSTRUCTION', value: 'CONSTRUCTION' },
                { text: 'GENERAL_PURPOSE', value: 'GENERAL_PURPOSE' },
                { text: 'Others', value: 'Others' },
            ],
            onFilter: (value, record) => record.typeOfGoodsTransported.indexOf(value) === 0,
        },
        { key: "driverName", title: "Driver Name", render: (text, record) => record.driver ? `${record.driver.firstName} ${record.driver.lastName}` : 'N/A' },
        { key: "driverEmail", title: "Driver Email", render: (text, record) => record.driver ? record.driver.emailAddress : 'N/A' },
        { key: "driverPhone", title: "Driver Phone", render: (text, record) => record.driver ? record.driver.telephone : 'N/A' },
        { key: "vehicleNumber", title: "Vehicle Number", render: (text, record) => record.vehicle ? record.vehicle.registrationNumber : 'N/A' },
        { key: "vehicleType", title: "Vehicle Type", dataIndex: "vehicleType" },
        {
            key: "action", title: "Action", align: "center",
            render: (user) => [
                <Button
                    onClick={() => {
                        setSelectedUser(user);
                        setIsViewPostModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>
            ],
        },
    ]);

    const loadRidesHistory = () => {
        getCustomerRideHistory()
            .then((res) => {
                console.log('Rides history data', res.data)
                setUsers(res.data);
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to get rides",
                });
            });
    };

    const downloadPDF = (data) => {
        const doc = new jsPDF();

        // Add logo
        //  const logo = MaxonLogo; // replace with your logo in base64 format
        //  const pageWidth = doc.internal.pageSize.getWidth();
        //  const logoWidth = 50; // width of the logo
        //  const logoX = (pageWidth / 2) - (logoWidth / 2); // calculate x-coordinate to center the logo
        //  doc.addImage(logo, 'PNG', logoX, 15, logoWidth, 50); // adjust y-coordinate to 15

        // Add title
        doc.setFontSize(22);
        const title = 'Maxon Ride Summary';
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const titleX = (pageWidth / 2) - (titleWidth / 2);
        doc.text(title, titleX, 10);

        // Reset font size for the rest of the document
        doc.setFontSize(12);

        const destinationParts = data.destinationLocationName.split(',');
        const shortDestination = destinationParts.slice(0, 2).join(',');

        const name = `Name: ${data.customer.firstName} ${data.customer.lastName}`;
        const origin = `Origin: ${data.originLocationName}`;
        const destination = `Destination: ${shortDestination}`;
        const typeOfGoods = `Type Of Goods: ${data.typeOfGoodsTransported}`;
        const distance = `Distance: ${data.distance}`;
        const capacity = `Capacity: ${data.capacity}`;
        const price = `Price: ${data.price}`;
        const driverName = `Driver Name: ${data.driver.firstName} ${data.driver.lastName}`;
        const driverEmail = `Driver Email: ${data.driver.emailAddress}`;
        const driverPhone = `Driver Phone: ${data.driver.telephone}`;
        const vehicleNumber = `Number Plate: ${data.vehicle.registrationNumber}`;
        const vehicleType = `Vehicle Type: ${data.vehicle.evehicleType}`;
        const vehicleModel = `Vehicle Model: ${data.vehicle.name}`;
        const color = `Color: ${data.vehicle.color}`;
        const vehicleCapacity = `Vehicle Capacity: ${data.vehicle.capacity}`;

        const texts = [name, origin, destination, typeOfGoods, distance, capacity, price, driverName, driverEmail, driverPhone, vehicleNumber, vehicleType, vehicleModel, color, vehicleCapacity];
         let y = 20;
        for (let text of texts) {
            doc.text(text, 40, y, { align: 'left'});
            y += 10;
        } 

        doc.save(`Maxon User Rides - ${data.customer.firstName} ${data.customer.lastName}.pdf`);
    };

    useEffect(() => {
        loadRidesHistory();
    }, []);

    return (
        <>
            <div>
                {/* modal view  */}
                <Modal
                    open={isViewPostModalVisible}
                    onCancel={() => {
                        setIsViewPostModalVisible(false);
                    }}
                    footer={[
                        <Button
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            onClick={() => {
                                users.forEach((user) => {
                                    if (user.id === selectedUser.id) {
                                        downloadPDF(user);
                                    }
                                });
                                setIsViewPostModalVisible(false);
                            }}
                            style={{ backgroundColor: "#0074D9", color: "white" }}
                        >
                            Download
                        </Button>,
                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item name={"name"} label="Name">
                            <p style={{ margin: 0 }}>{`${selectedUser?.customer.firstName} ${selectedUser?.customer.lastName}`}</p>
                        </Form.Item>
                        <Form.Item name={"emailAddress"} label="Email">
                            <p style={{ margin: 0 }}>{selectedUser?.customer.emailAddress}</p>
                        </Form.Item>
                        <Form.Item name={"originLocationName"} label="Origin">
                            <p style={{ margin: 0 }}>{selectedUser?.originLocationName}</p>
                        </Form.Item>
                        <Form.Item name={"destinationLocationName"} label="Destination">
                            <p style={{ margin: 0 }}>{selectedUser?.destinationLocationName}</p>
                        </Form.Item>
                        <Form.Item name={"typeOfGoodsTransported"} label="Type Of Goods">
                            <p style={{ margin: 0 }}>{selectedUser?.typeOfGoodsTransported}</p>
                        </Form.Item>
                        <Form.Item name={"driverName"} label="Driver Name">
                            <p style={{ margin: 0 }}>{`${selectedUser?.driver.firstName} ${selectedUser?.driver.lastName}`}</p>
                        </Form.Item>
                        <Form.Item name={"driverEmail"} label="Driver Email">
                            <p style={{ margin: 0 }}>{selectedUser?.driver.emailAddress}</p>
                        </Form.Item>
                        <Form.Item name={"driverPhone"} label="Driver Phone">
                            <p style={{ margin: 0 }}>{selectedUser?.driver.telephone}</p>
                        </Form.Item>
                        <Form.Item name={"vehicleNumber"} label="Vehicle Number">
                            <p style={{ margin: 0 }}>{selectedUser?.vehicle.registrationNumber}</p>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* main content */}
                <Table columns={tableCols} dataSource={users} />
            </div>
        </>
    )
};

export default UserRidesHistory;
