import axios from './index'

export function getOnRouteVehicles() {
  return axios.get('/dashboard/on-route-vehicles')
}

export function getActiveVehicles() {
  return axios.get('/dashboard/vehicles/active')
}

export function getInactiveVehicles() {
  return axios.get('/dashboard/vehicles/inactive')
}

export function getLoadingVehicles() {
  return axios.get('/dashboard/vehicles/loading')
}

export function getUnloadingVehicles() {
  return axios.get('/dashboard/vehicles/unloading')
}

export function getBookedVehicles() {
  return axios.get('/dashboard/vehicles/booked')
}

export function getIdleVehicles() {
  return axios.get('/dashboard/vehicles/idle')
}

export function getTrucks() {
  return axios.get('/dashboard/trucks')
}

export function getCars() {
  return axios.get('/dashboard/cars')
}

export function getAllOnboardedDrivers() {
  return axios.get('/dashboard/onboarded-drivers')
}

export function getVehicleOnboardingHistory() {
  return axios.get('/dashboard/vehicle/onboarding-history')
}

export function getUserOnboardingHistory() {
  return axios.get('/dashboard/user/onboarding-history')
}

export function getTripHistoryStatistics(month, year) {
  return axios.get(`/dashboard/trip/statistics?month=${month}&year=${year}`);
}

export function getCustomerRideHistory() {
  return axios.get('/dashboard/customer/ride-history')
}

export function getDriverRideHistory() {
  return axios.get('/dashboard/driver/ride-history')
}

export function getAssignedVehicle() {
  return axios.get('/dashboard/driver/onboarded-vehicle')
} 

export function createAddress({
  street = '',
  city = '',
  state = '',
  country = '',
  zipCode = '',
}= {}) {
  if (street === '' || city === '' || state === '' || country === '' || zipCode === '') {
    return Promise.reject('Missing required param(s): street, city, state, country, zipCode')
  }
  return axios.post('/address', {
    street: street,
    city: city,
    state: state,
    country: country,
    zipCode: zipCode,
  })
}

export function getVehicleLocation(vehicleId) {
  return axios.get(`/vehicle/${vehicleId}/track`)
}

export function updateVehicleLocation(vehicleId , {
  location = '',
}= {}) {
  return axios.patch(`/vehicle/${vehicleId}/track`, {
    location: location,
  })
}

export function getVehiclesAssignedToDriver({emailAddress = ''} = {}) {
  if (emailAddress === '') {
    return Promise.reject('Missing required param: emailAddress')
  }
  return axios.get(`/dashboard/driver/assigned-vehicle?emailAddress=${emailAddress}`)
}