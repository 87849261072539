import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { notification } from "antd";
import { resetPassword } from '../../service/profile.service';


const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Massitec Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const defaultTheme = createTheme();
  const navigate = useNavigate();

  const resetUserPassword = (event) => {
    event.preventDefault()
    const emailAddress = localStorage.getItem('email');
    const verificationCode = localStorage.getItem('verificationCode');
    resetPassword({ emailAddress, verificationCode, newPassword, newPasswordConfirm})
      .then((res) => {
        if (res && res.status === 200) {
          notification.success({
            message: 'Success! Your password has been reset. Please login.',
          })
          navigate('/login');
        }
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to reset password. Please try again.',
        })
      })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: '#fff',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
            padding: 2,
            borderRadius: 1,
            width: '33rem',
            height: '26rem',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset account password
          </Typography>
          <p>Enter a new password, the password should have at least 6 character </p>
          <Box
            component="form"
            noValidate
            onSubmit={resetUserPassword}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="new-password"
                  label="New Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => setNewPassword(event.target.value)}
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      borderColor: '#6CB4EE',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#6CB4EE',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  id="password"
                  autoComplete="confirm-password"
                  onChange={(event) => setNewPasswordConfirm(event.target.value)}
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      borderColor: '#6CB4EE',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#6CB4EE',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                  backgroundColor: 'theme.palette.primary.main',
                },
              }}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}

export default ResetPassword