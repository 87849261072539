import { useState, useEffect } from "react";
import { notification, Table, Button, Select, Form, Modal, Popconfirm, } from "antd";
import { getAllOnboardedDrivers } from "../service/dashboard.service";
import { deleteUserFromCorporate, updateUserRole } from "../service/maxon.service";

const { Option } = Select;

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};


const HistoryDriverOnboarding = () => {
    const [drivers, setDrivers] = useState([]);
    const [isUpdatePostModalVisible, setIsUpdatePostModalVisible] = useState(false);
    const [isViewPostModalVisible, setIsViewPostModalVisible] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [form] = Form.useForm();
    const [EUserType, setEUserType] = useState('');
    const [ERole, setERole] = useState('');

    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedDriver(post);
                        setIsViewPostModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "name", title: "Name", render: (text, record) => `${record.firstName} ${record.lastName}`, },
        { key: "emailAddress", title: "Email", dataIndex: "emailAddress" },
        { key: "euserType", title: "User Type", dataIndex: "euserType" },
        { key: "role", title: "Role", dataIndex: "role", render: roles => roles[0].name },
        {
            key: "action", title: "Action", align: "center",
            render: (text, user, postId) => [
                <Button onClick={() => editDriver(user)} style={{ marginRight: "7px", color: "#0074D9" }}>
                    Edit
                </Button>,
                <Button
                    onClick={() => {
                        setSelectedDriver(user);
                        setIsViewPostModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>,
                <Popconfirm
                    title="Are you sure to delete this post?"
                    onConfirm={() => handleDelete(user.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button style={{ marginRight: "7px", color: "#0074D9" }}>Delete</Button>
                </Popconfirm>,
            ],
        },
    ]);

    const loadDrivers = () => {
        getAllOnboardedDrivers()
            .then((res) => {
                console.log('User data', res.data)
                setDrivers(res.data);
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to get posts",
                });
            });
    };

    useEffect(() => {
        loadDrivers();
    }, []);

    const editDriver = (user) => {
        setSelectedDriver(user);
        form?.setFieldsValue({
            role: user.role[0]?.name,
        });
        setIsUpdatePostModalVisible(true);
    };

    const onEditPostSuccess = () => {
        loadDrivers();
        setSelectedDriver(null);
        setIsUpdatePostModalVisible(false);
    };


    const handleDelete = (userId) => {
        const corporateId = localStorage.getItem('corporateId');
        deleteUserFromCorporate(corporateId, userId)
            .then(() => {
                notification.success({
                    message: "User successfully deleted",
                });
                loadDrivers();
            }).catch((error) => {
                notification.error({
                    message: "Failed to delete user",
                });
            })
    }

    const updateRole = () => {
        form
            .validateFields()
            .then((values) => {

                if (ERole !== selectedDriver.role) {
                    const corporateId = localStorage.getItem('userId');
                    const emailAddress = localStorage.getItem('email');
                    updateUserRole(
                        corporateId,
                        selectedDriver.id, {
                        emailAddress: emailAddress,
                        EUserType: selectedDriver.euserType,
                        ERole: values.role,
                    })
                        .then((res) => {
                            notification.success({
                                message: "Role successfully updated",
                            });
                            onEditPostSuccess();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Failed to update role",
                            });
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onUpdatePostCancel = () => {
        setIsUpdatePostModalVisible(false);
    };

    return (
        <>
            <div>
                {/* modals edit */}
                <Modal
                    open={isUpdatePostModalVisible}
                    onCancel={onUpdatePostCancel}
                    footer={[
                        <Button onClick={onUpdatePostCancel}>Cancel</Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => updateRole()}
                        >
                            Update Role
                        </Button>,
                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item
                            name={"role"}
                            label="Role"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select>
                                <Option value="ADMIN">Admin</Option>
                                <Option value="USER">User</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* modal view  */}
                <Modal
                    open={isViewPostModalVisible}
                    onCancel={() => {
                        setIsViewPostModalVisible(false);
                    }}
                    footer={[
                        <Button
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                                editDriver(selectedDriver);
                            }}
                        >
                            Edit{" "}
                        </Button>,
                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item name={"name"} label="Name">
                            <p style={{ margin: 0 }}>{`${selectedDriver?.firstName} ${selectedDriver?.lastName}`}</p>
                        </Form.Item>

                        <Form.Item name={"emailAddress"} label="Email">
                            <p style={{ margin: 0 }}>{selectedDriver?.emailAddress}</p>
                        </Form.Item>

                        <Form.Item name={"euserType"} label="User Type">
                            <p style={{ margin: 0 }}>{selectedDriver?.euserType}</p>
                        </Form.Item>

                        <Form.Item name={"role"} label="Role">
                            <p style={{ margin: 0 }}>{selectedDriver?.role.map(role => role.name).join(', ')}</p>
                        </Form.Item>
                        <Form.Item name={"telephone"} label="Phone">
                            <p style={{ margin: 0 }}>{selectedDriver?.telephone}</p>
                        </Form.Item>
                        <Form.Item name={"verified"} label="Verified">
                            <p style={{ margin: 0 }}>{String(selectedDriver?.verified)}</p>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* main content */}
                <Table columns={tableCols} dataSource={drivers} />
            </div>
        </>
    )
};

export default HistoryDriverOnboarding;
