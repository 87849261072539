import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Person } from "@mui/icons-material";
import { mainListItems } from "../driver/ListItems";
import { Menu } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Copyright from "../../copyright/Copyright";
import { getActiveVehicles, getCustomerRideHistory, getInactiveVehicles } from "../../service/dashboard.service";
import HistoryDriverUserVehicle from "../../history/HistoryDriverUserVehicle";
import VehicleAssignToDriver from "../../history/VehicleAssignToDriver";
import { useNavigate } from "react-router-dom";



const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#024959',
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        backgroundColor: '#024959',
        color: '#fff',
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));


const defaultTheme = createTheme();

const DriverDashboard = () => {
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const openAnchor = Boolean(anchorEl);
    const username = localStorage.getItem('username');
    const [activeVehicles, setActiveVehicles] = useState(0);
    const [inactiveVehicles, setInactiveVehicles] = useState(0);
    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const userProfile = () => {
        navigate('/profile');
    }

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await getCustomerRideHistory();
    //             const rides = response.data;

    //             const activeVehicles = await getActiveVehicles();
    //             setActiveVehicles(activeVehicles.data.length);

    //             const inactiveVehicles = await getInactiveVehicles();
    //             setInactiveVehicles(inactiveVehicles.data.length);

    //         } catch (error) {
    //             console.error('Error fetching onboarded vehicles:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px", // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1, marginLeft: "1rem" }}
                            >
                                Maxon
                            </Typography>
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="#r"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Dashboard
                        </Typography>
                        <div>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Person />
                                <Typography variant="h6" color="inherit" noWrap style={{ fontSize: '1rem', marginLeft: '0.5rem' }}>
                                    {username}
                                </Typography>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={openAnchor}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={userProfile}>Profile</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer} sx={{ color: '#fff' }}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        {mainListItems}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: 'bold' }}>
                                    My Rides
                                </Typography>
                                <HistoryDriverUserVehicle title={'My Rides'} />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: 'bold' }}>
                                    Vehicle Assign To
                                </Typography>
                                <VehicleAssignToDriver title={'Vehicle Assign To'} />
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>

        </ThemeProvider >
    );
}

export default DriverDashboard;