import axios from './index'

export function customerVehicleRequest({
  destinationCoordinate = '',
  originCoordinate = '',
  destinationLocationName = '',
  originLocationName = '',
  EVehicleType = '',
  EGoodsType = '',
  capacity = '',
} = {}) {
  if (destinationCoordinate === '' && originCoordinate === '' && destinationLocationName &&  EVehicleType === '' && EGoodsType === '' && capacity === '') {
    return Promise.reject(
      'Missing required param(s): Please fill in all the required fields')
  }
  return axios.post('/vehicle-request/', {
    destinationCoordinate: destinationCoordinate,
    originCoordinate: originCoordinate,
    destinationLocationName: destinationLocationName,
    originLocationName: originLocationName,
    vehicleType: EVehicleType,
    typeOfGoodsTransported: EGoodsType,
    capacity: capacity,
  })
}

export function customerVehicleRequestAccept({
  distanceInMetres = '',
  price = '', 
  destinationCoordinate = '',
  originCoordinate = '',
  capacity = '',
  EVehicleType = '',
  destinationLocationName = '',
  originLocationName = '',
  EGoodsType = '',
  accepted = true,
} = {}) {
  if (destinationCoordinate === '' && originCoordinate === '' && destinationLocationName &&  EVehicleType === '' && EGoodsType === '' && capacity === '') {
    return Promise.reject(
      'Missing required param(s): Please fill in all the required fields')
  }
  return axios.post('/vehicle-request/accept', {
    distanceInMetres: distanceInMetres,
    price: price,
    destinationCoordinate: destinationCoordinate,
    originCoordinate: originCoordinate,
    capacity: capacity,
    vehicleType: EVehicleType,
    destinationLocationName: destinationLocationName,
    originLocationName: originLocationName,
    typeOfGoodsTransported: EGoodsType,
    accepted: accepted
  })
}