import React, { useState } from 'react'
import { createAddress } from '../../service/dashboard.service'
import { useNavigate } from 'react-router-dom'
import { notification } from "antd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";


const Copyright = (props) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="#">
                Massitec Website
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}
const defaultTheme = createTheme();

const UpdateAddress = () => {
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [zipCode, setZipCode] = useState('')
    const navigate = useNavigate();

    // const addressUpdate = (event) => {
    //     event.preventDefault()
    //     const userId = localStorage.getItem('userId')
    //     updateAddress(userId,
    //         { street, city, state, country, zipCode })
    //         .then((res) => {
    //             if (res && res.status === 201) {
    //                 const token = res.data.token;
    //                 localStorage.setItem('token', token);
    //                 // loginUserWithToken(token);
    //                 notification.success({
    //                     message: 'Successfully Updated Your Address.',
    //                 })
    //                 navigate('/login')
    //             }
    //         })
    //         .catch((error) => {
    //             notification.error({
    //                 message: 'Error',
    //                 description: error.message
    //             })
    //         })
    // }

    const createAddressUpdate = (event) => {
        event.preventDefault()
        createAddress({ street, city, state, country, zipCode })
            .then((res) => {
                notification.success({
                    message: 'Successfully Created Your Address.',
                })
                navigate('/login')
            })
            .catch((error) => {
                notification.error({
                    message: 'Failed to create address',
                })
            })
    }


    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#fff',
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                        padding: 2,
                        borderRadius: 1,
                        width: '30rem',
                        height: '37rem',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Set Up Your Address
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={createAddressUpdate}
                        sx={{ mt: 5 }}
                    >
                        <Grid container spacing={2.5}>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="street"
                                    label="Street"
                                    name="street"
                                    autoComplete="street"
                                    onChange={(event) => setStreet(event.target.value)}
                                    sx={{
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            borderColor: '#6CB4EE',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#6CB4EE',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="city"
                                    label="city"
                                    id="text"
                                    autoComplete='city'
                                    onChange={(event) => setCity(event.target.value)}
                                    sx={{
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            borderColor: '#6CB4EE',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#6CB4EE',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="state"
                                    label="State"
                                    id="state"
                                    autoComplete='state'
                                    onChange={(event) => setState(event.target.value)}
                                    sx={{
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            borderColor: '#6CB4EE',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#6CB4EE',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="country"
                                    label="Country"
                                    name="country"
                                    autoComplete="country"
                                    onChange={(event) => setCountry(event.target.value)}
                                    sx={{
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            borderColor: '#6CB4EE',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#6CB4EE',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="zipCode"
                                    label="Zip Code"
                                    name="zipCode"
                                    autoComplete="zipCode"
                                    onChange={(event) => setZipCode(event.target.value)}
                                    sx={{
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            borderColor: '#6CB4EE',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#6CB4EE',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    backgroundColor: 'theme.palette.primary.main',
                                },
                            }}
                        >
                            Set Address
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    )
}

export default UpdateAddress