import React from 'react'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Icon } from '@mui/material';


const Summary = ({ icon, bgStyles, number, title, description }) => {
    return (
        <Paper
            sx={{
                p: 2,
                margin: 'auto',
                maxWidth: 280,
                flexGrow: 1,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                minHeight: 140,
                boxShadow: 2,
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} sm container>
                    <Grid item xs container sx={{ mb: "1rem" }} >
                        <Grid item
                            sx={{ backgroundColor: bgStyles, padding: '1px 10px 1px 10px ', borderRadius: '15%' }}>
                            <div>
                                {icon && <Icon sx={{ fontSize: '1.5rem', fontWeight: 900 }}>{icon}</Icon>}
                                {/* <Img alt="truck" src={image}  sx={{backgroundColor: bgStyles}} /> */}
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ fontSize: '1.1rem', fontWeight: 600, marginLeft: '2rem' }}>
                            {number}{'\u00A0'}{title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" component="div">
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}


export default Summary