import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { notification } from "antd";
import { resetPasswordLink } from '../../service/profile.service';


const Copyright = (props) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="#">
                Massitec Website
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}


const SendLink = () => {
    const [emailAddress, setEmailAddress] = useState('');
    const navigate = useNavigate();
    const defaultTheme = createTheme();

    const sendLinkToResetPassword = (event) => {
        event.preventDefault()
        resetPasswordLink({ emailAddress })
            .then((res) => {
                localStorage.setItem('email', emailAddress);
                if (res && res.status === 200) {
                    notification.success({
                        message: 'We have sent you an email to reset your password. Please check your email.',
                    })
                    navigate('/VerifyEmailPasswordReset');
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Failed to send reset password email. Please try again.',
                })
            })
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#fff',
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                        padding: 2,
                        borderRadius: 1,
                        width: '30rem',
                        height: '23rem',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot your password?
                    </Typography>
                    <p>Enter email we will send you a link to reset your password.</p>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={sendLinkToResetPassword}
                        sx={{ mt: 3 }}
                    >
                        <Grid container spacing={2.5}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={(event) => setEmailAddress(event.target.value)}
                                    style={{ width: '400px' }}
                                    sx={{
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            borderColor: '#6CB4EE',
                                        },
                                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#6CB4EE',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    backgroundColor: 'theme.palette.primary.main',
                                },
                            }}
                        >
                            Request reset link
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    )
}

export default SendLink