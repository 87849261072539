import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import { Divider, Typography } from '@mui/material';
import DownloadingIcon from '@mui/icons-material/Downloading';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { getLoadingVehicles } from '../service/dashboard.service';
import { getUnloadingVehicles } from "../service/dashboard.service";
import { getBookedVehicles } from "../service/dashboard.service";
import { getOnRouteVehicles } from '../service/dashboard.service';



const VehiclesOverview = () => {
    const [loadingVehicles, setLoadingVehicles] = useState(0);
    const [unloadingVehicles, setUnloadingVehicles] = useState(0);
    const [bookedVehicles, setBookedVehicles] = useState(0);
    const [onRouteVehicles, setOnRouteVehicles] = useState(0);
  

    useEffect(() => {
        const fetchData = async () => {
            const loadingVehicles = await getLoadingVehicles();
            setLoadingVehicles(loadingVehicles.data.length);

            const unloadingVehicles = await getUnloadingVehicles();
            setUnloadingVehicles(unloadingVehicles.data.length);

            const bookedVehicles = await getBookedVehicles();
            setBookedVehicles(bookedVehicles.data.length);

            const onRouteVehicles = await getOnRouteVehicles();
            setOnRouteVehicles(onRouteVehicles.data.length);
        }
        fetchData()
    }, [])

    return (
        <Paper
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                minHeight: 240,

            }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm container>
                    <Grid item xs container sx={{ mb: "1rem", display: 'flex', flexDirection: 'column' }} >
                        <h3 style={{ textAlign: 'left', lineHeight: '0.5rem' }}>Vehicles Overview</h3>
                        <List
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginLeft: '0.3rem',
                                marginRight: '2rem',
                            }}
                        >
                            <li>On the way</li>
                            <li>Unloading</li>
                            <li>Loading</li>
                            <li>Booked</li>
                        </List>
                        <List sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                        }}>
                            <li style={{ backgroundColor: '#D9D9D9', width: '30%', height: '3rem', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{onRouteVehicles}</li>
                            <li style={{ backgroundColor: '#7F9AF8', width: '25%', height: '3rem', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{unloadingVehicles}</li>
                            <li style={{ backgroundColor: '#78EFF7', width: '25%', height: '3rem', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{loadingVehicles}</li>
                            <li style={{ backgroundColor: '#040639', width: '20%', height: '3rem', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{bookedVehicles}</li>
                        </List>
                        <Grid item sx={{ display: 'flex', flexDirection: 'row', }}>
                            <LocalTaxiIcon style={{ fontSize: 30 }} />
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '2rem' }}>
                                {onRouteVehicles} On the way
                            </Typography>
                            {/* <Typography variant="subtitle1" component="div" style={{ marginLeft: '13rem' }}>
                                2h 30min
                            </Typography>
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '5rem' }}>
                                55%
                            </Typography> */}
                        </Grid>
                        <Divider sx={{ marginBottom: '1.5rem' }} />
                        <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                            <DownloadingIcon style={{ fontSize: 30 }} />
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '2rem' }}>
                                {unloadingVehicles} Unloading
                            </Typography>
                            {/* <Typography variant="subtitle1" component="div" style={{ marginLeft: '13.5rem' }}>
                                2h 30min
                            </Typography>
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '5rem' }}>
                                55%
                            </Typography> */}
                        </Grid>
                        <Divider sx={{ marginBottom: '1.5rem' }} />
                        <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                            <AutorenewIcon style={{ fontSize: 30 }} />
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '2rem' }}>
                                {loadingVehicles} Loading
                            </Typography>
                            {/* <Typography variant="subtitle1" component="div" style={{ marginLeft: '14.3rem' }}>
                                2h 30min
                            </Typography>
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '5rem' }}>
                                55%
                            </Typography> */}
                        </Grid>
                        <Divider sx={{ marginBottom: '1.5rem' }} />
                        <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                            <CarRentalIcon style={{ fontSize: 30 }} />
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '2rem' }}>
                                {bookedVehicles} Booked
                            </Typography>
                            {/* <Typography variant="subtitle1" component="div" style={{ marginLeft: '14.3rem' }}>
                                2h 30min
                            </Typography>
                            <Typography variant="subtitle1" component="div" style={{ marginLeft: '5rem' }}>
                                55%
                            </Typography> */}
                        </Grid>
                        <Divider />

                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default VehiclesOverview