import React, { useState, useEffect } from 'react';
import { Grid, Paper, Select, MenuItem } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { getTripHistoryStatistics } from '../service/dashboard.service';
import { DownOutlined } from '@ant-design/icons';
import './Statistics.css';


const Statistics = () => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [tripData, setTripData] = useState({ carTripCount: 0, truckTripCount: 0 });

    useEffect(() => {
        async function fetchTripData() {
            try {
                const response = await getTripHistoryStatistics(selectedMonth + 1, selectedYear);
                setTripData(response.data);
                console.log("Trip Data", response.data);
            } catch (error) {
                console.error('Error fetching trip data:', error);
            }
        }

        fetchTripData();
    }, [selectedMonth, selectedYear]);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);

    const handleMonthChange = (event) => {
        setSelectedMonth(months.indexOf(event.target.value));
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight: 100 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm container>
                    <Grid item xs container sx={{ mb: '1rem', display: 'flex', flexDirection: 'column' }}>
                        <h3 style={{ textAlign: 'left', lineHeight: '0.5rem' }}>Statistics</h3>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '2rem' }}>
                            <Select
                                className="whiteIcon"
                                value={months[selectedMonth]}
                                onChange={handleMonthChange}
                                style={{ width: '100px', height: '30px', backgroundColor: '#4F9FB9', color: 'white', border: 'none', marginRight: '0.5rem' }}
                            >
                                {months.map((month, index) => (
                                    <MenuItem key={index} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                            <Select
                                className="whiteIcon"
                                value={selectedYear}
                                onChange={handleYearChange}
                                style={{ width: '100px', height: '30px', backgroundColor: '#4F9FB9', color: 'white', border: 'none' }}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <BarChart
                            width={270}
                            height={270}
                            xAxis={[{ scaleType: 'band', data: ['Delivery'] }]}
                            series={[
                                { data: [tripData.carTripCount === 0 ? 0 : tripData.carTripCount], label: 'Cars' },
                                { data: [tripData.truckTripCount === 0 ? 0 : tripData.truckTripCount], label: 'Truck' },
                            ]} />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Statistics;