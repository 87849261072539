import axios from './index'

export function register({
  firstName = '',
  lastName = '',
  emailAddress = '',
  password = '',
  confirmPassword = '',
  idCardNumber = '',
  telephone = '',
  EUserType = '',
  EIdentificationType = '',
  profilePictureUrl = ''
} = {}) {
  if (firstName === '' && lastName === '' && emailAddress === '') {
    return Promise.reject(
      'Missing required param(s): firstName, lastName, email',
    )
  }else if (password !== confirmPassword) {
    return Promise.reject(
      'Password and confirm password do not match',
    )
  }
  return axios.post('/auth/register', {
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    password: password,
    confirmPassword: confirmPassword,
    idCardNumber: idCardNumber,
    telephone: telephone,
    userType: EUserType,
    identificationType: EIdentificationType,
    profilePictureUrl: profilePictureUrl
  })
}

export function inviteUserRegister({
  firstName = '',
  lastName = '',
  emailAddress = '',
  password = '',
  confirmPassword = '',
  idCardNumber = '',
  telephone = '',
  EUserType = '',
  profilePictureUrl = ''
} = {}) {
  if (firstName === '' && lastName === '') {
    return Promise.reject(
      'Missing required param(s): firstName, lastName, email',
    )
  }
  return axios.post('/auth/invited/register', {
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    password: password,
    confirmPassword: confirmPassword,
    idCardNumber: idCardNumber,
    telephone: telephone,
    userType: EUserType,
    profilePictureUrl: profilePictureUrl
  })
}

export function login({ emailAddress = '', password = '' } = {}) {
  if (!emailAddress || !password) {
    return Promise.reject('Missing required param(s): email, password')
  }
  return axios.post('/auth/login', {
    emailAddress: emailAddress,
    password: password,
  })
}

export function verifyEmail({ emailAddress = '', verificationCode = 0 } = {}) {
  if (!emailAddress || !verificationCode) {
    return Promise.reject('Missing required param(s): email, emailAddress')
  }
  return axios.post('/auth/verify-email', {
    emailAddress: emailAddress,
    verificationCode: Number(verificationCode),
  })
}

export function resendVerification({
  emailAddress = ''
} = {}) {
  return axios.post('/auth/request-code', {
    emailAddress: emailAddress
  })
}

export function createCorporate({
  name = '',
  location = '',
  emailAddress = '',
}) {
  if (!name || !location || !emailAddress) {
    return Promise.reject('Missing required param(s): name, location, emailAddress')
  }
  return axios.post('/corporate', {
    name: name,
    location: location,
    emailAddress: emailAddress,
  })
}

export function updateUserRole(corporateId, userId, {
  emailAddress = '',
  EUserType = '',
  ERole = ''
} = {}) {
  if (ERole === '' && emailAddress === '' && EUserType === '') {
    return Promise.reject('Missing required param(s): corporateId, userId, role')
  }
  return axios.put(`/corporate/${corporateId}/user/${userId}`, {
    emailAddress: emailAddress,
    userType: EUserType,
    role: ERole,
  })
}

export function deleteUserFromCorporate(id, userId) {
  if (id === '' && userId === '') {
    return Promise.reject('Missing required param(s): id, userId')
  }
  return axios.delete(`/corporate/${id}/user/${userId}`)
}