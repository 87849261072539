import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBidGPIX9x-Bz-N8Y0w_s69X4UJtg6xzcU",
  authDomain: "maxon-mobile.firebaseapp.com",
  databaseURL: "https://maxon-mobile-default-rtdb.firebaseio.com",
  projectId: "maxon-mobile",
  storageBucket: "maxon-mobile.appspot.com",
  messagingSenderId: "533460993739",
  appId: "1:533460993739:web:5365b188c5a56acd1abd4e",
  measurementId: "G-3BKW8E9NWB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);