import { useState, useEffect } from "react";
import { notification, Table, Button, Select, Form, Modal, Popconfirm, } from "antd";
import { getDriverRideHistory } from "../service/dashboard.service";
import { updateUserRole } from "../service/maxon.service";
import { driverTripStatus } from "../service/driver.service";

const { Option } = Select;

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 19,
        offset: 1,
    },
};

const spanStyleEdit = {
    cursor: "pointer",
    textDecoration: "none",
    color: "#0074D9",
};


const HistoryDriverUserVehicle = () => {
    const [drivers, setDrivers] = useState([]);
    const [isUpdatePostModalVisible, setIsUpdatePostModalVisible] = useState(false);
    const [isViewPostModalVisible, setIsViewPostModalVisible] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [form] = Form.useForm();
    const [ERole, setERole] = useState('');
    const [tripStatus, setTripStatus] = useState('');
    const [vehicleTripStatus, setVehicleTripStatus] = useState('');

    const [tableCols] = useState([
        {
            key: "no",
            title: "No",
            dataIndex: "title",
            render: (text, post, index) => (
                <span
                    style={spanStyleEdit}
                    onClick={() => {
                        setSelectedDriver(post);
                        setIsViewPostModalVisible(true);
                    }}
                >
                    {index + 1}. {post.title}
                </span>
            ),
        },
        { key: "name", title: "Cust Name", render: (text, record) => `${record.customer.firstName} ${record.customer.lastName}`, },
        { key: "emailAddress", title: "Email", render: (text, record) => record.customer.emailAddress },
        { key: "telephone", title: "Phone", render: (text, record) => record.customer.telephone },
        { 
            key: "originLocationName", 
            title: "Origin", 
            dataIndex: "originLocationName",
            render: (text) => {
                const parts = text.split(',');
                return parts.slice(0, 5).join(',');
            }
        },
        {
            key: "destinationLocationName",
            title: "Destination",
            render: (text, record) => {
                const words = record.destinationLocationName.split(' ');
                return words.slice(0, 3).join(' ');
            }
        },
        { key: "typeOfGoodsTransported", title: "Type Of Goods", render: (text, record) => record.typeOfGoodsTransported },
        {key: "tripStatus", title: "Trip Status", render: (text, record) => record.vehicle.vehicleTripStatus},
        {
            key: "action", title: "Action", align: "center",
            render: (text, user, postId) => [
                <Button
                    onClick={() => {
                        setSelectedDriver(user);
                        setIsViewPostModalVisible(true);
                    }}
                    style={{ marginRight: "7px", color: "#0074D9" }}
                >
                    View
                </Button>
            ],
        },
    ]);

    const loadDrivers = () => {
        getDriverRideHistory()
            .then((res) => {
                console.log('Driver data', res.data)
                res.data.forEach((rideHistory) => {
                    console.log('Vehicle trip status', rideHistory.vehicle.vehicleTripStatus)
                    setVehicleTripStatus(rideHistory.vehicle.vehicleTripStatus);
                });
                setDrivers(res.data);
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to get Drivers",
                });
            });
    };

    useEffect(() => {
        loadDrivers();
    }, []);

    const editDriver = (user) => {
        setSelectedDriver(user);
        form?.setFieldsValue({
            role: user.role[0]?.name,
        });
        setIsUpdatePostModalVisible(true);
    };

    // const makeDriverAvailable = (user) => {
    //     selectedVehicle(user);
    //     form?.setFieldsValue({
    //         available: user.available,
    //     });
    //     setIsUpdatePostModalVisible(true);
    // };

    const onEditPostSuccess = () => {
        loadDrivers();
        setSelectedDriver(null);
        setIsUpdatePostModalVisible(false);
    };

    // const handleDelete = (userId) => {
    //     const corporateId = localStorage.getItem('corporateId');
    //     deleteUserFromCorporate(corporateId, userId)
    //         .then(() => {
    //             notification.success({
    //                 message: "User successfully deleted",
    //             });
    //             loadDrivers();
    //         }).catch((error) => {
    //             notification.error({
    //                 message: "Failed to delete user",
    //             });
    //         })
    // }

    const updateRole = () => {
        form
            .validateFields()
            .then((values) => {

                if (ERole !== selectedDriver.role) {
                    const corporateId = localStorage.getItem('userId');
                    const emailAddress = localStorage.getItem('email');
                    updateUserRole(
                        corporateId,
                        selectedDriver.id, {
                        emailAddress: emailAddress,
                        EUserType: selectedDriver.euserType,
                        ERole: values.role,
                    })
                        .then((res) => {
                            notification.success({
                                message: "Role successfully updated",
                            });
                            onEditPostSuccess();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Failed to update role",
                            });
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const upDateDriverTripStatus = (tripStatus) => {
        const cucustomerTripId = localStorage.getItem('customerTripId');
        driverTripStatus(cucustomerTripId, { EVehicleTripStatus: tripStatus })
            .then((res) => {
                if (res && res.status === 200) {
                    notification.success({
                        message: 'Successfully updated trip status',
                    });
                    setIsUpdatePostModalVisible(false);
                }
            })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: error && error.message ? error.message : 'Unknown error',
                });
            });
    }

    const onUpdatePostCancel = () => {
        setIsUpdatePostModalVisible(false);
    };

    const showConfirm = (title, tripStatus) => {
        Modal.confirm({
            title: title,
            onOk: () => {
                setTripStatus(tripStatus);
                upDateDriverTripStatus(tripStatus);
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    }

    return (
        <>
            <div>
                {/* modals make vehicle available */}
                <Modal
                    open={isUpdatePostModalVisible}
                    onCancel={onUpdatePostCancel}
                    footer={[
                        <Button onClick={onUpdatePostCancel}>Cancel</Button>,
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => updateRole()}
                        >
                            Update Role
                        </Button>,
                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item
                            name={"role"}
                            label="Role"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select>
                                <Option value="ADMIN">Admin</Option>
                                <Option value="USER">User</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* modal view  */}
                <Modal
                    open={isViewPostModalVisible}
                    onCancel={() => {
                        setIsViewPostModalVisible(false);
                    }}
                    footer={[
                        <Button
                            onClick={() => {
                                setIsViewPostModalVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            type="primary"
                            disabled={vehicleTripStatus === 'IDLE' || vehicleTripStatus === 'TRIP_STARTED' || vehicleTripStatus === 'ON_ROUTE'}
                            style={{ backgroundColor: 'green', borderColor: 'green' }}
                            onClick={() => showConfirm('Are you sure you want to start the trip?', 'ON_ROUTE')}
                        >
                            Start Trip
                        </Button>,

                        <Button
                            type="primary"
                            disabled={vehicleTripStatus === 'IDLE' || vehicleTripStatus === 'BOOKED' || vehicleTripStatus === 'TRIP_STARTED'}
                            onClick={() => showConfirm('Are you sure you want to end the trip?', 'IDLE')}
                        >
                            End Trip
                        </Button>,

                    ]}
                >
                    <Form
                        {...layout}
                        name="nest-messages"
                        form={form}
                        style={{ paddingTop: "35px" }}
                    >
                        <Form.Item name={"name"} label="Customer Name">
                            <p style={{ margin: 0 }}>{`${selectedDriver?.customer.firstName} ${selectedDriver?.customer.lastName}`}</p>
                        </Form.Item>
                        <Form.Item name={"emailAddress"} label="Customer Email">
                            <p style={{ margin: 0 }}>{selectedDriver?.customer.emailAddress}</p>
                        </Form.Item>
                        <Form.Item name={"originLocationName"} label="Origin Name">
                            <p style={{ margin: 0 }}>{selectedDriver?.originLocationName}</p>
                        </Form.Item>
                        <Form.Item name={"telephone"} label="Telephone No">
                            <p style={{ margin: 0 }}>{selectedDriver?.customer.telephone}</p>
                        </Form.Item>
                        <Form.Item name={"destinationLocationName"} label="Destination Name">
                            <p style={{ margin: 0 }}>{selectedDriver?.destinationLocationName}</p>
                        </Form.Item>
                        <Form.Item name={"typeOfGoodsTransported"} label="Type Of Goods">
                            <p style={{ margin: 0 }}>{selectedDriver?.typeOfGoodsTransported}</p>
                        </Form.Item>
                        <Form.Item name={"price"} label="Price">
                            <p style={{ margin: 0 }}>{'GHC '}{selectedDriver?.price}</p>
                        </Form.Item>
                        <Form.Item name={"capacity"} label="Capacity">
                            <p style={{ margin: 0 }}>{selectedDriver?.capacity}{' t'}</p>
                        </Form.Item>
                        <Form.Item name={"vehicleType"} label="Vehicle Type">
                            <p style={{ margin: 0 }}>{selectedDriver?.vehicleType}</p>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* main content */}
                <Table columns={tableCols} dataSource={drivers} />
            </div>
        </>
    )
};

export default HistoryDriverUserVehicle;
