import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { register } from "../../service/maxon.service";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";


const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Massitec Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const defaultTheme = createTheme();

const Register = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('');
  const [idCardNumber, setIdCardNumber] = useState('')
  const [telephone, setTelephone] = useState('')
  const [EUserType, setEUserType] = useState('')
  const [EIdentificationType, setEIdentificationType] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState('')
  const navigate = useNavigate();


  const userRegister = (event) => {
    event.preventDefault()
    register({ firstName, lastName, emailAddress, password, confirmPassword, idCardNumber, telephone, EUserType, EIdentificationType, profilePictureUrl })
      .then((res) => {
        if (res && res.status === 201) {
          const token = res.data.token;
          localStorage.setItem('token', token);
          localStorage.setItem("email", emailAddress);
          notification.success({
            message: 'Registration successful! Please verify your email.',
          })
          navigate('/verify');
        }
      })
      .catch((error) => {
        notification.error({
          message: 'Registration failed! Please try again later.',
          description: error.response.data.message || error.message,
        })
      })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={userRegister}
            sx={{ mt: 5 }}
          >
            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="last-name"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => setEmailAddress(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  id="password"
                  autoComplete="confirm-password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="id_card"
                  label="ID Card"
                  name="id_card"
                  autoComplete="id_card"
                  onChange={(event) => setIdCardNumber(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  onChange={(event) => setTelephone(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ minWidth: 190 }} size="midium">
                  <InputLabel id="user-type-label">
                    User Type
                  </InputLabel>
                  <Select
                    labelId="user-type-label"
                    id="user-type-select"
                    required
                    value={EUserType}
                    label="User Type"
                    sx={{ textAlign: "left" }}
                    onChange={(event) => setEUserType(event.target.value)}
                  >
                    <MenuItem value={'DRIVER'}>Driver</MenuItem>
                    <MenuItem value={'INDIVIDUAL'}>Individual</MenuItem>
                    <MenuItem value={'CORPORATE'}>Corporate</MenuItem>
                    <MenuItem value={'CUSTOMER'}>Customer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ minWidth: 190 }} size="midium">
                  <InputLabel id="identification-type-label">
                    Identification Type
                  </InputLabel>
                  <Select
                    labelId="identification-type-label"
                    id="identification-type-select"
                    required
                    value={EIdentificationType}
                    label="Identification Type"
                    sx={{ textAlign: "left" }}
                    onChange={(event) => setEIdentificationType(event.target.value)}
                  >
                    <MenuItem value={'VOTERS_ID'}>Voters ID</MenuItem>
                    <MenuItem value={'NATIONAL_ID'}>National ID</MenuItem>
                    <MenuItem value={'DRIVERS_LICENSE'}>Drivers License</MenuItem>
                    <MenuItem value={'PASSPORT'}>Passport</MenuItem>
                    <MenuItem value={'BIRTH_CERTIFICATE'}>Birth Certificate</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider >
  );
};

export default Register;
