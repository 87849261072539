import React, { useEffect, useState } from 'react'
import { notification, Form } from "antd";
import { getUserProfile, updateUserEmail, updateUserPassword, updateUserProfile } from '../service/profile.service';
import './UserProfile.css'
import { storage } from '../service/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';


const UserProfile = () => {
    const [users, setUsers] = useState([]);
    const [isUpdatePostModalVisible, setIsUpdatePostModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [selectedUser, setSelectedUser] = useState(null);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [telephone, setTelephone] = useState('')
    const [idCard, setIdCard] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [activeTab, setActiveTab] = useState('account-general');
    const defaultAvatarUrl = "https://bootdey.com/img/Content/avatar/avatar1.png";
    const [avatar, setAvatar] = useState(defaultAvatarUrl);
    const [uploadImage, setUploadImage] = useState(null);
    const [profilePictureUrl, setProfilePictureUrl] = useState('');
    const navigate = useNavigate();
    const userType = localStorage.getItem('userType');


    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const handleFileChange = (e) => {
        setUploadImage(e.target.files[0]);
    };

    const handleUpload = () => {
        if (uploadImage === null) return;
        const storageRef = ref(storage, `drivers/${uploadImage.name + v4()}`);
        uploadBytes(storageRef, uploadImage).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                localStorage.setItem('imageUrl', downloadURL);
                setProfilePictureUrl(downloadURL);
                setAvatar(downloadURL);
                notification.success({
                    message: "Image uploaded successfully",
                });
            })
        }).catch((error) => {
            notification.error({
                message: "Failed to upload image",
                description: error.message,
            });
        });
    };

    const handleCancel = () => {
        if (userType === 'CORPORATE') {
            navigate("/corporate-dashboard", { replace: true });
        } else if (userType === 'DRIVER') {
            navigate("/driver-dashboard", { replace: true });
        } else {
            navigate("/individual-dashboard", { replace: true });
        }
    };

    const getUserProfileDetails = () => {
        getUserProfile()
            .then((res) => {
                setUsers(res.data)
                setSelectedUser(res.data)
                console.log("User Profile data", res.data)
                const data = {
                    data: {
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        emailAddress: res.data.emailAddress,
                        telephone: res.data.telephone,
                        idCard: res.data.idCardNumber,
                        profilePictureUrl: res.data.profilePictureUrl
                    }
                };

                setFirstName(res.data.firstName)
                setLastName(res.data.lastName)
                setEmailAddress(res.data.emailAddress)
                setTelephone(res.data.telephone)
                setIdCard(res.data.idCardNumber)
                setAvatar(res.data.profilePictureUrl ? res.data.profilePictureUrl : defaultAvatarUrl)

            })
            .catch((error) => {
                notification.error({
                    message: "Failed to fetch user profile details",
                })
            })
    }

    const handleUserUpdate = (event) => {
        event.preventDefault();
        updateUserProfile({ firstName, lastName, profilePictureUrl })
            .then((res) => {
                console.log("Data being sent", { firstName, lastName, profilePictureUrl });
                notification.success({
                    message: "User profile updated successfully",
                })
                setIsUpdatePostModalVisible(false);
                getUserProfileDetails()
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to update user profile",
                })
            })
    }

    const handleUserEmailUpdate = (event) => {
        event.preventDefault();
        updateUserEmail({ emailAddress })
            .then((res) => {
                notification.success({
                    message: "User email updated successfully",
                })
                setIsUpdatePostModalVisible(false);
                getUserProfileDetails()
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to update user email",
                })
            })
    }

    const handleChangePassword = (event) => {
        event.preventDefault();
        updateUserPassword({ oldPassword, newPassword, confirmPassword })
            .then((res) => {
                notification.success({
                    message: "User password updated successfully",
                });
                setIsUpdatePostModalVisible(false);
                getUserProfileDetails();
            })
            .catch((error) => {
                notification.error({
                    message: "Failed to update user password",
                });
            });
    }

    useEffect(() => {
        getUserProfileDetails()
    }, [])

    return (

        <div className="container light-style flex-grow-1 container-p-y">
            <h4 className="font-weight-bold py-3 mb-4">
                Account settings
            </h4>

            <div className="card overflow-hidden">
                <div className="row no-gutters row-bordered row-border-light">
                    <div className="col-md-3 pt-0" style={{ textAlign: 'left' }}>
                        <div className="list-group list-group-flush account-settings-links">
                            <a className={`list-group-item list-group-item-action ${activeTab === 'account-general' ? 'active' : ''}`} onClick={() => handleTabClick('account-general')} href="#account-general">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user" style={{ marginRight: '1rem' }}>
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                Profile Information
                            </a>
                            <a className={`list-group-item list-group-item-action ${activeTab === 'account-change-password' ? 'active' : ''}`} onClick={() => handleTabClick('account-change-password')} href="#account-change-password">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield mr-2" style={{ marginRight: '1rem' }}>
                                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                </svg>
                                Change password
                            </a>
                            <a className={`list-group-item list-group-item-action ${activeTab === 'account-info' ? 'active' : ''}`} onClick={() => handleTabClick('account-info')} href="#account-info">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-lock mr-2" style={{ marginRight: '1rem' }}>
                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                                Update Email</a>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content">
                            <div className={`tab-pane fade ${activeTab === 'account-general' ? 'show active' : ''}`} id="account-general">
                                <div className="card-body media align-items-center" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <img src={avatar} alt="User Profile" className="d-block ui-w-80" style={{ borderRadius: '50%' }} />
                                    <div className="media-body ml-4">
                                        <label className="btn btn-outline-primary">
                                            Choose image
                                            <input
                                                type="file"
                                                className="account-settings-fileinput"
                                                onChange={handleFileChange}
                                                disabled={userType !== 'DRIVER'}
                                            />
                                        </label> &nbsp;

                                        <button type="button" className="btn btn-default md-btn-flat" style={{ backgroundColor: '#4CAF50', color: 'white', padding: '7px 20px', margin: '8px 0', border: 'none', cursor: 'pointer', width: '33%' }} onClick={handleUpload}>Upload</button>
                                        <div className="small mt-1" style={{ marginLeft: '2rem', color: '#cecece' }}>
                                            Allowed JPG, GIF or PNG. Max size of 800K
                                        </div>
                                    </div>
                                </div>
                                <hr className="border-light m-0" />
                                <div className="card-body" style={{ textAlign: 'left' }}>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label className="form-label">First Name</label>
                                        <input type="text" className="form-control mb-1" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
                                    </div>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label className="form-label">Last Name</label>
                                        <input type="text" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
                                    </div>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label className="form-label">E-mail</label>
                                        <input type="text" className="form-control mb-1" value={emailAddress} placeholder="user@gmail.com" readOnly />
                                    </div>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label className="form-label">Phone</label>
                                        <input type="text" className="form-control" value={telephone} placeholder='Phone' readOnly />
                                    </div>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label className="form-label">ID</label>
                                        <input type="text" className="form-control" value={idCard} placeholder='ID Card' readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'account-change-password' ? 'show active' : ''}`} id="account-change-password">
                                <div className="card-body pb-2" style={{ textAlign: 'left' }}>
                                    <div className="form-group">
                                        <label className="form-label">Old password</label>
                                        <input type="password" className="form-control" value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">New password</label>
                                        <input type="password" className="form-control" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Repeat new password</label>
                                        <input type="password" className="form-control" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab === 'account-info' ? 'show active' : ''}`} id="account-info">
                                <hr className="border-light m-0" />
                                <div className="card-body pb-2" style={{ textAlign: 'left' }}>
                                    <div className="form-group">
                                        <label className="form-label">New Email</label>
                                        <input type="email" className="form-control" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
                                    </div>
                                </div>
                                <hr className="border-light m-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-right mt-3">
                {activeTab === 'account-general' && (
                    <button type="button" className="btn btn-primary" onClick={handleUserUpdate}>Save changes</button>
                )}
                {activeTab === 'account-change-password' && (
                    <button type="button" className="btn btn-primary" onClick={handleChangePassword}>Save changes</button>
                )}
                {activeTab === 'account-info' && (
                    <button type="button" className="btn btn-primary" onClick={handleUserEmailUpdate}>Save changes</button>
                )}
                &nbsp;
                <button type="button" className="btn btn-default" onClick={handleCancel}>Cancel</button>
            </div>
        </div>
    )

}

export default UserProfile